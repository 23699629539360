import * as React from "react"
// import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


import ContentWrapper from "../../components/wrappers/contentWrapper"
import Heading from "../../components/typography/heading";
import SubHeading from "../../components/typography/subHeading";
import SpanBold from "../../components/typography/SpanBold";
import ColoredWrapper from "../../components/wrappers/colorWrapper";


const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const AccountsReceivablePage = () => {
  return (
    <Layout>
      <Seo
        title="Accounts Receivable"
        description="Helping small businesses with record keeping, bank and credit card reconciliations, tax remittance, and monthly statements"
        canonical="https://labellebookkeeping.com/services/accounts-receivable/"
      />
      <HeroImage>
        <StaticImage
          src="../../images/hero/hero_accounts_payable_services.jpg"
          alt="accounts receivable services"
          objectPosition="50% 50%"
        />
      </HeroImage>

      <ContentWrapper>
        <Heading>
          Accounts Receivable Services
        </Heading>
        <p>
          Invoices are essential to ensuring you get paid by your customers or clients. They serve as a legally
          enforceable agreement between your business and your clients and allow you to track your sales and manage your
          cash flow effectively.
        </p>
        <p>
          <SpanBold>Services include:</SpanBold>
        </p>
        <p>
          <ul>
            <li>Completely paperless invoice management</li>
            <li>Invoice creation and issuance</li>
            <li>Payment collection and follow up</li>
            <li>Application and tracking of customer payments</li>
            <li>Unique schedule of A/R reports based on your company’s needs</li>
          </ul>
        </p>
      </ContentWrapper>
      <ColoredWrapper>
        <ContentWrapper>
          <SubHeading>
            Payment Collection and Follow-Up
        </SubHeading>
          <p>
            The longer it takes for a business to invoice and collect money on your accounts receivable, the less likely
            the business is to collect those finances owed. By keeping on top of your accounts receivable management,
            you
            will keep your cash flow healthy and predictable. I have the processes, experience, and practices to monitor
            your receivables, nudge customers when they need it, and improve on collecting.
          </p>
          <SubHeading>Time Efficiency</SubHeading>
          <p>Managing your accounts receivable is time consuming. Let me save you time by managing it for you. I will
            input and issue invoices and receipts directly to your customers or staff, track your updating A/R listings,
            and keep you updated on the information you need to run your business comfortably.</p>

          <SubHeading>Do you deal with bad debt?</SubHeading>
          <p>I am not a collections agency. My process on collecting payment is to reach out multiple times through
            different avenues and channels. In the rare cases where this approach is unsuccessful, it’s up to your
            company
            to decide if legal or further action is required.</p>
        </ContentWrapper>
      </ColoredWrapper>
    </Layout>
  )
}

export default AccountsReceivablePage
